<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">商品列表</div>
      <div>
        <div>
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='add'
            >+添加商品</el-button
          >
          <!-- <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button :label="item.id" v-for='(item,index) in qslist' :key='index'>{{item.name}}</el-radio-button>
            <el-radio-button label="判断题"></el-radio-button>
            <el-radio-button label="填空题"></el-radio-button>
            <el-radio-button label="主观题"></el-radio-button>
            <el-radio-button label="看图说话"></el-radio-button>
          </el-radio-group> -->
        </div>
        <div>
         <el-select v-model="brandId" placeholder="品牌筛选" clearable  class="cinput ac" @change="list">
                <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
          <el-select
          clearable 
            v-model="categoryId"
            placeholder="类型筛选"
            class="cinput ac"
            style="margin: 0 23px"
            @change="list"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="请输入搜索内容"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="goodsInfo.name" label="商品名称" align="center" >
        </el-table-column>
        <el-table-column prop="goodsInfo.priceMoney" align="center" label="缩略图"  width="100">
          <template slot-scope="scope">
            <div>
              <img :src='scope.row.goodsInfo.thumbNailUrl' style="width:100%;height:100%"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsInfo.priceMoney" align="center" label="商品价格" :show-overflow-tooltip="true" >
          <!-- <template slot-scope="scope">
            <div>
              {{scope.row.priceMoney}}
            </div>
          </template> -->
        </el-table-column>
        <!-- <el-table-column prop="startTime" align="center" label="生效状态" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div> 
              {{date>scope.row.gettime?'已开始':'未开始'}}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="goodsInfo.pricePoint" align="center" label="积分价格" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="goodsInfo.stock" align="center" label="库存" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="goodsInfo.des" align="center" label="商品描述" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="goodsInfo.startOnlineTime" align="center" label="开始时间"  >
        </el-table-column>
        <el-table-column prop="goodsInfo.endOnlineTime" align="center" label="结束时间" >
        </el-table-column>


        <el-table-column prop="address" label="操作" align="center" width="240">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
              <!-- <el-link type="primary" :underline="false" @click='look(scope.row)' v-if="date>scope.row.gettime" style="margin:0 31px">查看统计</el-link>
              <el-link type="primary" :underline="false" @click='details2(scope.row)' v-if="date<scope.row.gettime" style="margin:0 31px">添加试题</el-link> -->
              <el-link type="primary" :underline="false" @click='dele(scope.row)' style="margin:0 20px">删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {questiontypeList,qsList,levelList,qssaveOrUpdate,PaperList,delePaper,goodsList,goodsClassListAll,deleGoods} from "../../apis/index";
export default {
  data() {
    return {
        classList:[],
        categoryId:'',
        brandList:[],
        brandId:'',
      date:'',
      timeState:'',
      timeStateList:[
        {
          id:1,
          name:'未生效'
        },
        {
          id:2,
          name:'已生效'
        },
        {
          id:3,
          name:'已过期'
        },
      ],
       typeList:[
                 {
                    id:1,
                    name:'小程序'
                },{
                    id:2,
                    name:'课程包'
                }
            ],
            type:'',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [
       
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
     if(newdata==''){
        this.isshow=false
       this.input=''
       this.list()
     }else{
       this.isshow=true
     }
    },
    radio(newdata, olddata) {
     this.list()
    },
    value(){
      this.list()
    },
  },
  created(){
      this.brandList=this.$store.state.brandList
    this.getclassList()
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    this.list()
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  filters:{
    filtersDate(date){
     return new Date(date).getTime()
    },
  },
  methods: {
       async getclassList(){//获取全部分类列表
         const {data}=await goodsClassListAll({parentId:0})
         this.classList=data.data
      },
    look(item){//查看统计
    console.log(item)
    let items=JSON.stringify(item)
      this.$router.push({path:'/surveyPapgeDetails2',query:{items}})
    },
   async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
   async list(){
      const params={
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        brandId:this.brandId,
        categoryId:this.categoryId
        // timeState:this.timeState,
        // type:1
      }
     const {data}=await goodsList(params)
     if(data.code==200){
    //    let list=data.data.records.map(item=>{
    //      item.gettime=new Date(item.startTime).getTime()
    //      return item
    //    })
    
    data.data.records.forEach(item=>{
      item.goodsInfo.priceMoney=Number(item.goodsInfo.priceMoney)/100
    })
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
    },
     async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;
    
    },
    add(){//添加题目
      this.$router.push({path:'/mallAdd',query:{isadd:true}})
    },
    details(row){//查看详情
      this.$router.push({path:'/mallAdd',query:{row:JSON.stringify(row)}})
    },
     details2(row){//添加试题
      this.$router.push({path:'/surveyPapgeAdd2',query:{row:JSON.stringify(row)}})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
     dele(row) {
        const params={
         id:row.goodsInfo.id,
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           deleGoods(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
