import { render, staticRenderFns } from "./mallList.vue?vue&type=template&id=061ae279&scoped=true&"
import script from "./mallList.vue?vue&type=script&lang=js&"
export * from "./mallList.vue?vue&type=script&lang=js&"
import style0 from "./mallList.vue?vue&type=style&index=0&id=061ae279&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061ae279",
  null
  
)

export default component.exports